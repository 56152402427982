import { type Hospital, type Staff, api } from '@atnd/api-client'
import { hospitalAccessToken, hospitalRefreshToken, setClinicAliasToCookie } from '@atnd/utils'
import type { Spread } from '@atnd/utils/types'
import { atom } from 'jotai'

export const staffAtom = atom<Spread<Staff> | undefined>(undefined)
// 病院情報はスタッフで管理しているので、病院を更新するときはスタッフの病院も更新する
export const mutateStaffHospitalAtom = atom(null, (get, set, hospital: Hospital) => {
	const staff = get(staffAtom)
	if (!staff) return

	const hospitals = staff.hospitals.map((h) => (h.id === hospital.id ? hospital : h))

	set(staffAtom, {
		...staff,
		hospitals,
	})
})

export const loginWithIdAndPasswordAtom = atom(
	null,
	async (
		_,
		set,
		{
			clinic_alias,
			id,
			password,
		}: {
			id: string
			password: string
			clinic_alias: string
		},
	) => {
		const { access, refresh, staff } = await api.login_staff.$post({
			body: {
				clinic_alias,
				id,
				password,
			},
		})

		if (!staff || !access || !refresh) {
			throw new Error('ログインに失敗しました')
		}

		set(staffAtom, {
			...staff,
		})

		hospitalAccessToken.set(access)
		hospitalRefreshToken.set(refresh)
		setClinicAliasToCookie(clinic_alias)

		return access
	},
)

export const loginAtom = atom(
	null,
	async (
		_,
		set,
		{
			clinic_alias,
			token,
		}: {
			clinic_alias: string
			token: string
		},
	) => {
		const { access, refresh, staff } = await api.login_staff.$post({
			body: {
				id: undefined,
				password: undefined,
				clinic_alias,
			},
			config: {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		})

		if (!staff || !access || !refresh) {
			throw new Error('ログインに失敗しました')
		}

		set(staffAtom, staff)
		hospitalAccessToken.set(access)
		hospitalRefreshToken.set(refresh)
		setClinicAliasToCookie(clinic_alias)

		return staff
	},
)

export const logoutAtom = atom(null, (_, set) => {
	set(staffAtom, undefined)
	hospitalAccessToken.remove()
	hospitalRefreshToken.remove()
})
